import React from "react";
import { Link } from "gatsby";

import './Nav.scss';
import { Logo } from "@components/Logo";

const Navbar = () => {

  return (
    <nav>
      <div className="item">
        <Link to="/" className="no-hover" title="Home">
          <Logo />
        </Link>
      </div>
      <div className="item">
        <Link to="/releases/" activeClassName="active" title="Releases">Releases</Link>
      </div>
      <div className="item">
        <Link to="/mixes/" activeClassName="active" title="Mixes">Mixes</Link>
      </div>
      <div className="item">
        <Link to="/videos/" activeClassName="active" title="Videos">Videos</Link>
      </div>
      <div className="item">
        <Link to="/about/" activeClassName="active" title="About">About</Link>
      </div>
    </nav>
  );
};

export default Navbar;
