import React, { createContext, useReducer } from "react";

const initialState = {
    consentGiven: typeof window !== 'undefined'
        ? Boolean(localStorage.getItem('consentGiven'))
        : false
};

const Reducer = (state, action) => {
    switch (action.type) {
        case "GIVE_CONSENT":
            return {
                ...state,
                consentGiven: true
            };

        default:
            return state;
    }
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
};

const Context = createContext(initialState);

export {
    Context,
    Store,
};
