import React from 'react';
import romanize from 'romanize';

const Copyright = () => {
	const fullYear = new Date().getFullYear();
	const romanizedYear = romanize(fullYear);

	return (
		<li title={`${fullYear} © Nutriot Recordings`}>{`${romanizedYear} © Nutriot Recordings`}</li>
	);
}

export default Copyright;
