import React from 'react';

import './Layout.scss';
import { Nav } from '@components/Nav';
import { Footer } from '@components/Footer';

const Layout = ({ children }) => {
  return (
    <div className="grid">
      <Nav />
        {children}
      <Footer />
    </div>
  );
}

export default Layout;
