module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nutriot Recordings","short_name":"nutriot","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icons":[{"src":"src/images/icon.svg","type":"image/svg","purpose":"any","sizes":"any"},{"src":"src/images/maskable_icon.png","type":"image/png","purpose":"maskable"}],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
