import React from 'react';

import { Store } from '@components/Store';
import { Layout } from '@components/Layout';

const wrapPageElement = ({ element }) => {
  return (
    <Store>
      <Layout>
          {element}
      </Layout>
    </Store>
  );
}

export {
  wrapPageElement
};
