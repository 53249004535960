import React, { useEffect, useState } from 'react';

const Anchor = props => {
  const [target, setTarget] = useState(null);

  useEffect(() => {
    try {
      const isSameSite = new URL(props.href).hostname !== 'nutriot.com';
      setTarget(isSameSite ? '_blank' : undefined);
    } catch (error) {
      setTarget(undefined)
    }
  }, [props.href]);

  return (
    <a
        className={props.className}
        href={props.href}
        rel={
          props.external
            ? 'nofollow noopener noreferrer'
            : props.rel
              ? props.rel
              : undefined
        }
        target={target}
        title={
          props.title
            ? props.title
            : typeof props.children === 'string'
              ? props.children
              : undefined
        }
    >
        {props.children}
    </a>
  );
};

export default Anchor;
