import React, { useContext } from 'react';

import './Footer.scss';
import { Anchor } from '@components/Anchor';
import { Context } from '@components/Store';
import { Instagram, Mastodon, YouTube } from '@components/Icons';
import { Copyright } from '@components/Copyright';

const Footer = () => {
  const [state] = useContext(Context);

  return (
    <footer style={state.player ? { marginBottom: '1em'} : undefined}>
      <ul className="inline unstyled">
        <Copyright />
        <li>
          <Anchor
            href="https://instagram.com/nutriot"
            rel="me"
            title="Follow Nutriot Recordings on Instagram"
            external="true"
          >
            <Instagram />
          </Anchor>

					<Anchor
							href="https://mastodon.social/@nutriot"
							rel="me"
							title="Follow Nutriot Recordings on Mastodon"
							external="true"
					>
							<Mastodon />
					</Anchor>

          <Anchor
            href="https://youtube.com/c/NutriotRecordings"
            rel="me"
            title="Follow Nutriot Recordings on YouTube"
            external="true"
          >
            <YouTube />
          </Anchor>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
